import React from "react";
import Logo from "../../utils/template/Logo/Logo";
import Menu from "../../utils/template/Menu/Menu";
import Header from "../../utils/template/Header/Header";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";

export default (props) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="page">
      <Logo />
      <Menu />
      <Header />
      <div className="content mt-4 ml-4">
        <Row gutter={16}>
          {(user.type.includes("responsavel") ||
            user.type.includes("admin")) && (
            <Col span={8} xs={12} md={12} lg={6} className="my-2">
              <Card title="Relatórios" bordered={false}>
                <Link to="/relatorios">Ver todos os relatórios de aula</Link>
              </Card>
            </Col>
          )}
          {/* {(user.type.includes("responsavel") ||
            user.type.includes("admin")) && (
            <Col span={8} xs={12} md={12} lg={6} className="my-2">
              <Card title="Mensagens" bordered={false}>
                <Link to="/mensagens">Ver todas as mensagens recebidas</Link>
              </Card>
            </Col>
          )} */}
          {/* <Col span={8} xs={12} md={6} lg={6} className="my-2">
            <Card title="Agenda" bordered={false}>
              <Link to="/agenda">Visualizar agenda</Link>
            </Card>
          </Col> */}
        </Row>
      </div>
    </div>
  );
};
