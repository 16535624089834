import "./Menu.css";
import React from "react";
import { Link } from "react-router-dom";
import { IoIosSpeedometer } from "react-icons/io";
import { GoGear } from "react-icons/go";
import {
  FaClipboardList,
  FaComment,
  FaBars,
  FaChalkboardTeacher,
  FaUserTie,
  FaUserGraduate,
  FaCalendarAlt,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../useWindowDimensions";
import { useDispatch } from "react-redux";
import { clear } from "../../store/actions/userActions";
import { useSelector } from "react-redux";

export default (props) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const user = useSelector((state) => state.user);

  let location = useLocation();
  return width > 768 ? (
    <aside className="menu-area">
      <nav className="menu">
        {/*------------------------- PAINEL ---------------------------------------------------------  */}
        <Link
          to="/inicio"
          className={
            location.pathname === "/inicio" || location.pathname === "/"
              ? "active"
              : "inactive"
          }
        >
          <IoIosSpeedometer size={25} className="icon" />
          <span>Painel</span>
        </Link>
        {/* ------------------------------------------- AGENDA ---------------------------------------- */}
        {/* <Link
          to="/agenda"
          className={location.pathname === "/agenda" ? "active" : "inactive"}
        >
          <FaCalendarAlt size={25} className="icon" />
          <span>Agenda</span>
        </Link> */}
        {/*------------------------- RELATÓRIOS ---------------------------------------------------------  */}
        {(user.type.includes("responsavel") || user.type.includes("admin")) && (
          <Accordion>
            <Accordion.Toggle
              as={Card}
              eventKey="0"
              className={
                location.pathname.includes("/relatorios")
                  ? "active"
                  : "inactive"
              }
            >
              <FaClipboardList size={25} className="icon" />
              <span>Relatórios</span>
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey="0"
              className={
                location.pathname === "/relatorios" ? "active show" : "inactive"
              }
            >
              <Link to="/relatorios">Todos os relatórios</Link>
            </Accordion.Collapse>
          </Accordion>
        )}
        {/*------------------------- MENSAGENS ---------------------------------------------------------  */}
        {/* {(user.type.includes("responsavel") || user.type.includes("admin")) && (
          <Link
            to="/mensagens"
            className={
              location.pathname.includes("/mensagens") ? "active" : "inactive"
            }
          >
            <FaComment size={25} className="icon" />
            <span>Mensagens</span>
          </Link>
        )} */}
        {/*------------------------- CONFIGURAÇÕES ---------------------------------------------------------  */}
        <Accordion>
          <Accordion.Toggle
            as={Card}
            eventKey="6"
            className={
              location.pathname.includes("/configuracoes")
                ? "active"
                : "inactive"
            }
          >
            <GoGear size={25} className="icon" />
            <span>Configurações</span>
          </Accordion.Toggle>
          <Accordion.Collapse
            eventKey="6"
            className={
              location.pathname === "/configuracoes"
                ? "active show"
                : "inactive"
            }
          >
            <Link to="/configuracoes/alterar-senha">Alterar senha</Link>
          </Accordion.Collapse>
        </Accordion>
        {/*------------------------- LOGOUT ---------------------------------------------------------  */}
        <button className="logout-button" onClick={() => dispatch(clear())}>
          <FiLogOut size={25} className="icon" />
          <span>Logout</span>
        </button>
      </nav>
    </aside>
  ) : (
    // --------------------------------------------------MENU RESPONSIVO --------------------------------------------------
    <aside className="menu-area">
      <Accordion>
        <Accordion.Toggle as={Card} eventKey="1">
          <FaBars size={25} className="icon" />
        </Accordion.Toggle>
        {/* ------------------------------------------- PAINEL ------------------------------------------------------- */}
        <Accordion.Collapse
          eventKey="1"
          className={
            location.pathname === "/relatorios" ? "active" : "inactive"
          }
        >
          <nav className="menu">
            <Link
              to="/"
              className={
                location.pathname === "/inicio" || location.pathname === "/"
                  ? "active"
                  : "inactive"
              }
            >
              <IoIosSpeedometer size={25} className="icon" />
              <span>Painel</span>
            </Link>
            {/* ------------------------------------------- AGENDA ------------------------------------------------------- */}
            <Link
              to="/agenda"
              className={
                location.pathname === "/agenda" ? "active" : "inactive"
              }
            >
              <FaCalendarAlt size={25} className="icon" />
              <span>Agenda</span>
            </Link>
            {/* ------------------------------------------- RELATÓRIOS ------------------------------------------------------- */}
            {(user.type.includes("responsavel") ||
              user.type.includes("admin")) && (
              <Accordion>
                <Accordion.Toggle
                  as={Card}
                  eventKey="0"
                  className={
                    location.pathname.includes("/relatorios")
                      ? "active"
                      : "inactive"
                  }
                >
                  <FaClipboardList size={25} className="icon" />
                  <span>Relatórios</span>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey="0"
                  className={
                    location.pathname === "/relatorios"
                      ? "active show"
                      : "inactive"
                  }
                >
                  <Link to="/relatorios">Todos os relatórios</Link>
                </Accordion.Collapse>
              </Accordion>
            )}
            {/* ------------------------------------------- MENSAGENS ------------------------------------------------------- */}
            {(user.type.includes("responsavel") ||
              user.type.includes("admin")) && (
              <Link
                to="/mensagens"
                className={
                  location.pathname.includes("/mensagens")
                    ? "active"
                    : "inactive"
                }
              >
                <FaComment size={25} className="icon" />
                <span>Mensagens</span>
              </Link>
            )}
            {/*------------------------- CONFIGURAÇÕES ---------------------------------------------------------  */}
            <Accordion>
              <Accordion.Toggle
                as={Card}
                eventKey="6"
                className={
                  location.pathname.includes("/configuracoes")
                    ? "active"
                    : "inactive"
                }
              >
                <GoGear size={25} className="icon" />
                <span>Configurações</span>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey="6"
                className={
                  location.pathname === "/configuracoes"
                    ? "active show"
                    : "inactive"
                }
              >
                <Link to="/configuracoes/alterar-senha">Alterar senha</Link>
              </Accordion.Collapse>
            </Accordion>
            {/* ------------------------------------------- LOGOUT ------------------------------------------------------- */}
            <button className="logout-button" onClick={() => dispatch(clear())}>
              <FiLogOut size={25} className="icon" />
              <span>Logout</span>
            </button>
          </nav>
        </Accordion.Collapse>
      </Accordion>
    </aside>
  );
};
