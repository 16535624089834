import React from 'react'
import {Switch, Route, Redirect} from 'react-router'

import Login from "./Login/Login"
import RecuperarSenha from './RecuperarSenha/RecuperarSenha'
import NovaSenha from './RecuperarSenha/NovaSenha'

export default props => 
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/recuperar-senha" component={RecuperarSenha} />
      <Route path="/nova-senha" component={NovaSenha} />
      <Redirect from="*" to="/"/>

    </Switch>