import {
  SORT_REPORTS,
  FILTER_REPORT_BY_NAME,
  CLEAR_FILTER,
  FILTER_REPORT_BY_DATE,
  UPLOADING_REPORT,
  REPORT_SENT,
  SET_REPORTS,
} from "./actionTypes";
import axios from "axios";
import { message } from "antd";
import { findPosition } from "../reducers/reportReducer";

export const fetchReports = () => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    const type = getState().user.type;
    dispatch(uploadingReport());
    if (type === "responsavel") {
      axios
        .get(
          `/responsaveis/${getState().user.localId}/children.json?auth=${token}`
        )
        .catch((err) =>
          message.error(
            "R01. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
          )
        )
        //Caso o get tenha sido bem sucedido, preenche um array com os resultado e o repassa ao reducer.
        .then((res) => {
          const children = res.data;
          let data = [];
          let cont = 0;

          Promise.all(
            children.map((name) => {
              console.log(name);
              axios
                .get(
                  `/relatorios.json?auth=${token}&orderBy="student"&equalTo="${name}"`
                )
                .then((res) => {
                  cont++;
                  for (let item in res.data) {
                    if (
                      res.data[item].student &&
                      children.includes(res.data[item].student) &&
                      res.data[item].verified
                    ) {
                      data.push({ ...res.data[item] });
                    }
                  }
                  if (cont === children.length) {
                    //Ordenação entre relatórios lidos e não lidos
                    let verified = [];
                    let unverified = [];
                    data.forEach((item) => {
                      if (item.read) {
                        verified.push({ ...item });
                      } else {
                        unverified.push({ ...item });
                      }
                    });

                    //Ordenação por timestamp
                    let ver_sorted = [];
                    let unv_sorted = [];
                    ver_sorted = verified.sort(
                      (a, b) => b.timestamp - a.timestamp
                    );
                    unv_sorted = unverified.sort(
                      (a, b) => b.timestamp - a.timestamp
                    );

                    localStorage.setItem(
                      "reports",
                      JSON.stringify(unv_sorted.concat(ver_sorted))
                    );
                    dispatch(setReports(unv_sorted.concat(ver_sorted)));
                  }
                });
            })
          );
        });
    } else if (type === "aluno-responsavel" || type === "admin") {
      let name = getState().user.name;
      axios
        .get(
          `/relatorios.json?auth=${token}&orderBy="student"&equalTo="${name}"`
        )
        .catch((err) =>
          message.error(
            "R01. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
          )
        )
        .then((res) => {
          const rawReports = res.data;
          let reports = [];
          for (let key in rawReports) {
            if (
              rawReports[key].student &&
              name === rawReports[key].student &&
              rawReports[key].verified
            ) {
              reports.push({ ...rawReports[key] });
            }
          }
          let sorted = [];
          sorted = reports.sort((a, b) => b.timestamp - a.timestamp);
          localStorage.setItem("reports", JSON.stringify(sorted));
          dispatch(setReports(sorted));
        });
    } else {
      dispatch(reportUploaded());
    }
  };
};

export const setReports = (reports) => {
  return {
    type: SET_REPORTS,
    payload: reports,
  };
};

export const uploadingReport = () => {
  return {
    type: UPLOADING_REPORT,
  };
};

export const reportUploaded = () => {
  return {
    type: REPORT_SENT,
  };
};

export const sortReports = () => {
  return {
    type: SORT_REPORTS,
  };
};

export const filterByName = (filter) => {
  return {
    type: FILTER_REPORT_BY_NAME,
    payload: filter,
  };
};

export const editReport = (report) => {
  report.read = true;

  return (dispatch, getState) => {
    dispatch(uploadingReport());
    const token = getState().user.userValidation.token;
    axios
      .patch(`/relatorios/${report.id}.json?auth=${token}`, { ...report })
      .catch((err) =>
        message.error(
          "R02. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
        )
      )
      .then(() => {
        let storedReports = getState().reports.reports;
        const index = findPosition(storedReports, report.id);
        if (index >= 0) {
          storedReports[index] = { ...report };

          //Ordenação entre relatórios lidos e não lidos
          let verified = [];
          let unverified = [];
          storedReports.forEach((item) => {
            if (item.read) {
              verified.push({ ...item });
            } else {
              unverified.push({ ...item });
            }
          });

          //Ordenação por timestamp
          let ver_sorted = [];
          let unv_sorted = [];
          ver_sorted = verified.sort((a, b) => b.timestamp - a.timestamp);
          unv_sorted = unverified.sort((a, b) => b.timestamp - a.timestamp);

          localStorage.setItem(
            "reports",
            JSON.stringify(unv_sorted.concat(ver_sorted))
          );
          dispatch(setReports(unv_sorted.concat(ver_sorted)));
        }
      });
  };
};

export const filterByDate = (initialDate, finalDate) => {
  return {
    type: FILTER_REPORT_BY_DATE,
    payload: {
      initialDate: initialDate,
      finalDate: finalDate,
    },
  };
};

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};
