import React from 'react'
import {Switch, Route, Redirect} from 'react-router'
 
import PaginaInicial from '../modules/PaginaInicial/PaginaInicial'
//Páginas dos relatórios
import Relatorios from '../modules/Relatorios/Busca/Busca'
import LeituraRelatorio from '../modules/Relatorios/Leitura/Leitura'
//Páginas das mensagens
import Mensagens from '../modules/Mensagens/Busca/Busca'
import MensagensRelatorio from '../modules/Mensagens/Leitura/Leitura'
//Configurações
import AlterarSenha from '../modules/Configuracao/AlterarSenha'
//Agenda
import Detalhes from '../modules/Agenda/Detalhes'
import Agenda from '../modules/Agenda/Principal'



export default props => (
  <Switch>
    <Route exact path="/" component={PaginaInicial} />
    <Route exact path="/relatorios" component={Relatorios} />
    {/* <Route exact path="/mensagens" component={Mensagens} /> */}
    <Route exact path="/relatorios/visualizar/:id" component={LeituraRelatorio} />

    {/* <Route exact path="/mensagens" component={Mensagens} />
    <Route exact path="/mensagens/visualizar/:id" component={MensagensRelatorio} /> */}

    <Route exact path="/configuracoes/alterar-senha" component={AlterarSenha} />
    {/* <Route exact path="/agenda" component={Agenda} /> */}

    <Redirect from="*" to="/"/>
  </Switch>
)
