import React, { Component } from "react";
import { connect } from "react-redux";
import "./AuthOrApp.css";
import App from "../../../App";
import {
  userNotAuthorized,
  loadingUser,
  userLogged,
  clear,
} from "../../../utils/store/actions/userActions";
import ProgressBar from "../../../utils/template/ProgressBar/ProgressBar";
import moment from "moment";
import { fetchReports, setReports } from "../../../utils/store/actions/reportsActions";
import { fetchMessages } from "../../../utils/store/actions/messagesActions";
import { fetchAllUsers } from "../../../utils/store/actions/registerActions";
import { fetchUserEvents } from "../../../utils/store/actions/calendarActions";
import SemAutorizacao from "../SemAutorizacao";

class AuthOrApp extends Component {
  state = {
    loading: true,
  };

  isTokenValidated = (userData) => {
    //Recupera informações do usuário do localStorage
    const currentTime = moment();
    //Se houver informações no storage, verifica validade do token do usuário
    //O token tem validade de 1h, mas estou considerando apenas 55 min.
    if (userData) {
      const expirationTime = moment(userData.tokenSetOn).add(55, "minute");
      const expired = currentTime.isAfter(expirationTime);
      //Se estiver expirado, aciona uma action para atualizar o token
      if (expired) {
        this.props.onClear();
        return { validated: false, timeLeft: 0 };
      } else {
        const diff = expirationTime.diff(currentTime);
        return { validated: true, timeLeft: diff };
      }
    } else {
      this.props.onUserNotAuthorized();
      return { validated: false, timeLeft: 0 };
    }
  };

  outterTimer = null;
  innerTimer = null;

  componentDidMount() {
    this.props.onLoadingUser();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const storedReports = JSON.parse(localStorage.getItem("reports"));    
    this.outterTimer = setTimeout(() => {
      const tokenIsValidated = this.isTokenValidated(userData);
      if (tokenIsValidated.validated) {
        this.props.onLoadLoggedUser(userData)
        if(storedReports){
          this.props.onSetReports(storedReports)
        } else {
          this.props.onFetchReports();
        }
        //this.props.onFetchMessages();
        //this.props.onFetchAllUsers();
        //this.props.onFetchAllEvents();
        this.innerTimer = setTimeout(
          () => this.props.onClear(),
          tokenIsValidated.timeLeft
        );
      }
    }, 50);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.outterTimer);
    clearTimeout(this.innerTimer);
  };

  render() {
    return this.state.loading ? (
      <div className="loading">
        <ProgressBar
          visualParts={[
            {
              percentage: "100%",
              color: "orange",
            },
          ]}
        />
      </div>
    ) : this.props.user && this.props.tokenValid ? (
      <App />
    ) : (
      <SemAutorizacao />
    );
  }
}

const mapStateToProps = ({ user }) => ({
  tokenValid: user.userValidation.tokenValid,
  user: user.name,
  loading: user.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUserNotAuthorized: () => dispatch(userNotAuthorized()),
    onLoadingUser: () => dispatch(loadingUser()),
    onLoadLoggedUser: (user) => dispatch(userLogged(user)),
    onFetchReports: () => dispatch(fetchReports()),
    onSetReports: (reports) => dispatch(setReports(reports)),
    //onFetchMessages: () => dispatch(fetchMessages()),
    //onFetchAllUsers: () => dispatch(fetchAllUsers()),
    //onFetchAllEvents: () => dispatch(fetchUserEvents()),
    onClear: () => dispatch(clear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
