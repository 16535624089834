import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import moment from "moment";
import { Dropdown, Button, message, Menu as DropdownMenu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import {
  fetchReports,
  filterByName,
  clearFilter,
  filterByDate,
} from "../../../utils/store/actions/reportsActions";
import { Table } from "antd";
const { Search } = Input;

const { RangePicker } = DatePicker;

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

const menu = (
  <DropdownMenu onClick={handleMenuClick}>
    <DropdownMenu.Item key="1">Exluir selecionados</DropdownMenu.Item>
  </DropdownMenu>
);

class BuscaMensagens extends Component {
  state = {
    filter: "",
    dates: [null, null],
    firstDate: null,
    finalDate: null,
    messages: [],
    search: false,
    width: 1080,
  };

  componentDidMount() {
    window.addEventListener("resize", () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateWindowDimensions());
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  columns_lg = [
    {
      title: "Conversa",
      dataIndex: "id",
      key: "id",
      render: (id) => <Link to={`/mensagens/visualizar/${id}`}>{id}</Link>,
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Aluno",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Horário",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Lido",
      dataIndex: "readUser",
      key: "readUser",
      render: (readUser) => (readUser ? <CheckOutlined /> : null),
    },
  ];

  columns_md = [
    {
      title: "Conversa",
      dataIndex: "id",
      key: "id",
      render: (id) => <Link to={`/mensagens/visualizar/${id}`}>{id}</Link>,
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Lido",
      dataIndex: "readUser",
      key: "readUser",
      render: (readUser) => (readUser ? <CheckOutlined /> : null),
    },
  ];

  columns_xs = [
    {
      title: "Conversa",
      dataIndex: "id",
      key: "id",
      render: (id) => <Link to={`/mensagens/visualizar/${id}`}>{id}</Link>,
    },
    {
      title: "Lido",
      dataIndex: "readUser",
      key: "readUser",
      render: (readUser) => (readUser ? <CheckOutlined /> : null),
    },
  ];

  clear = () => {
    this.props.onClear();
    this.setState({
      filter: "",
      dates: [null, null],
      firstDate: null,
      finalDate: null,
      search: false,
    });
  };

  handleChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  handleCalendarChange = (dates, stringDates) => {
    this.setState({
      dates,
      firstDate: stringDates[0],
      finalDate: stringDates[1],
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.onFilterByName(this.state.filter);
    }
  };

  selectMessages = (allMessages) => {
    let messages = [];
    allMessages.forEach((message) => {
      if (message.user !== this.props.user) {
        messages.push(message);
      }
    });
    return messages;
  };

  handleSearch = (search) => {
    this.setState({ search: true });
    this.props.onFilterByName(search);
  };

  unverifiedMessages = (messages) => {
    let unverified = [];
    messages.forEach((message) => {
      if (!message.readUser) {
        unverified.push(message);
      }
    });
    return unverified;
  };

  verifiedMessages = (messages) => {
    let verified = [];
    messages.forEach((message) => {
      if (message.readUser) {
        verified.push(message);
      }
    });
    return verified;
  };

  render() {
    const filter = this.props.filteredReports.length;
    let data = [];
    if (this.state.search && filter > 0) {
      this.props.filteredReports.forEach((report, index) => {
        if (report.messages) {
          const lastMessage = report.messages.length - 1;
          data.push({
            ...report.messages[lastMessage],
            student: report.student,
            id: report.id,
            key: index,
          });
        }
      });
    } else if (!this.state.search) {
      this.props.reports.forEach((report, index) => {
        let flag = false;
        if (report.messages) {
          let lastMessage = report.messages.length - 1;
          while (report.messages[lastMessage].user === this.props.user) {
            lastMessage--;
            if (lastMessage < 0) {
              flag = true;
              break;
            }
          }
          if (flag) {
            data = [];
          } else {
            data.push({
              ...report.messages[lastMessage],
              student: report.student,
              id: report.id,
              key: index,
            });
          }
        }
      });
    } else if (this.state.search && filter === 0) {
      data = [];
    }
    data.sort(
      (a, b) => moment(b.date, "DD-MM-YYYY") - moment(a.date, "DD-MM-YYYY")
    );

    data.sort(
      (a, b) => moment(b.date, "DD-MM-YYYY") - moment(a.date, "DD-MM-YYYY")
    );
    const sortedMessages = this.unverifiedMessages(data).concat(
      this.verifiedMessages(data)
    );
    return (this.props.type.includes("responsavel") || this.props.type.includes("admin")) && (
      <div className="page search">
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <Logo />
        <Menu />
        <div className="content mt-4 ml-4">
          <h1 className="title">Mensagens</h1>
          {/*--------------------------------- BOTÕES SUPERIORES ------------------------------ */}
          <Row className="mx-0">
            {/*..................................... Ação em massa .............................. */}
            <Col lg={1} md={5} xs={5} className="pl-0 my-1 mr-3">
              <Dropdown overlay={menu}>
                <Button size="small" className="acoes-massa">
                  Ações em massa <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            {/*..................................... Atualizar ................................... */}
            <Col lg={2} md={5} xs={5} className="pl-0 my-1">
              <Button
                size="middle"
                className="mr-4"
                onClick={() => {
                  this.props.onFetchReports();
                }}
              >
                Atualizar
              </Button>
            </Col>
            {/*..................................... Calendário ................................ */}
            <Col lg={2} md={11} xs={11} className="pl-0 my-1">
              <RangePicker
                locale={locale}
                size="large"
                value={this.state.dates}
                onChange={this.handleCalendarChange}
                format="DD/MM/YYYY"
              />
            </Col>
            {/*..................................... Filtrar ................................... */}
            <Col lg={1} md={4} xs={4} className="pl-0 my-1">
              <Button
                size="middle"
                className="mr-4"
                onClick={() =>
                  this.props.onFilterByDate(
                    this.state.firstDate,
                    this.state.finalDate
                  )
                }
              >
                Filtrar por data
              </Button>
            </Col>
            {/*..................................... Limpar .................................... */}
            <Col
              lg={1}
              md={4}
              xs={3}
              className={
                this.state.width > 540 ? "pr-5 my-1 mr-5" : "my-1 ml-5"
              }
            >
              <Button size="middle" onClick={this.clear}>
                Limpar
              </Button>
            </Col>
            {/*.............................. Campo de pesquisa .................................... */}
            <Col lg={3} md={10} xs={8} className="pl-0 my-1">
              <Search
                size="large"
                value={this.state.filter}
                onChange={this.handleChange}
                onSearch={(value) => this.handleSearch(value)}
                enterButton
                onKeyPress={(e) => this.handleKeyPress(e)}
                placeholder="Buscar responsável"
              />
            </Col>
          </Row>
          {/*--------------------------------- TABELA DE RESULTADOS ------------------------------ */}
          <div className="mt-5 mr-5">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-dark" : "table-row-light"
              }
              rowSelection={{ type: "checkbox" }}
              dataSource={sortedMessages}
              columns={
                this.state.width >= 935
                  ? this.columns_lg
                  : this.state.width >= 760 && this.state.width <= 935
                  ? this.columns_md
                  : this.columns_xs
              }
              locale={{
                emptyText: (
                  <img src={require("../../../assets/images/no-data.png")} alt="Imagem 'não encontrado'"/>
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, user }) => ({
  reports: reports.reports,
  filteredReports: reports.filteredReports,
  user: user.name,
  type: user.type
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReports: () => dispatch(fetchReports()),
    onFilterByName: (filter) => dispatch(filterByName(filter)),
    onClear: () => dispatch(clearFilter()),
    onFilterByDate: (initialDate, finalDate) =>
      dispatch(filterByDate(initialDate, finalDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuscaMensagens);
