import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../utils/template/Header/Header";
import Logo from "../../../utils/template/Logo/Logo";
import Menu from "../../../utils/template/Menu/Menu";
import { Dropdown, Button,  Table, Spin, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CheckOutlined, CalendarTwoTone } from "@ant-design/icons";
import {
  fetchReports,
  sortReports,
  clearFilter,
  filterByDate,
} from "../../../utils/store/actions/reportsActions";
import { filterByName } from "../../../utils/store/reducers/reportReducer";

import moment from "moment";
const { Search } = Input;

class BuscaRelatorios extends Component {
  state = {
    filter: "",
    dates: [null, null],
    firstDate: null,
    finalDate: null,
    search: false,
    width: 1080,
    isLoading: true,
    data: []
  };

  componentDidMount() {
    window.addEventListener("resize", () => this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateWindowDimensions());
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  columns_lg = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id) =>
          <Link to={`/relatorios/visualizar/${id}`}>{id}</Link>
    },
    {
      title: "Aluno",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Horário",
      dataIndex: "startOfClass",
      key: "startOfClass",
    },
    {
      title: "Tutor",
      dataIndex: "tutor",
      key: "tutor",
    },
    {
      title: "Lido",
      dataIndex: "read",
      key: "read",
      render: (read) => (read ? <CheckOutlined /> : null),
    },
  ];

  columns_md = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) =>
          <Link to={`/relatorios/visualizar/${record.id}`}>{record.id}</Link>
    },
    {
      title: "Aluno",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Enviado",
      dataIndex: "read",
      key: "read",
      render: (read) => (read ? <CheckOutlined /> : null),
    },
  ];

  columns_xs = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) =>
          <Link to={`/relatorios/visualizar/${record.id}`}>{record.id}</Link>
    },
    {
      title: "Enviado",
      dataIndex: "read",
      key: "read",
      render: (read) => (read ? <CheckOutlined /> : null),
    },
  ];

  clear = () => {
    this.props.onClear();
    this.setState({
      filter: "",
      dates: [null, null],
      firstDate: null,
      finalDate: null,
      search: false,
    });
  };

  handleChange = (e) => {
    let filtered = []
    filtered = filterByName(this.props.reports, e.target.value)
    this.setState({...this.state, filter: e.target.value, data: [...filtered] });
  };


  render() {
    let data = this.props.reports

    return (this.props.type.includes("responsavel") || this.props.type.includes("admin")) && (
      <div className="page">
        <Logo />
        <Menu />
        {/*--------------------------------- HEADER ----------------------------------------- */}
        <Header />
        <div className="content mt-4 ml-4">
          <h1 className="title">Relatórios de aula</h1>
          {/*--------------------------------- BOTÕES SUPERIORES ------------------------------ */}
          <Row className="mx-0 justify-content-between">
            {/*..................................... Atualizar ................................... */}
            <Col lg={1} md={2} xs={1} className="pl-0 my-1">
              <Button
                size="middle"
                className="mr-4"
                onClick={() => this.props.onFetchReports()}
              >
                Atualizar
              </Button>
            </Col>
            {/*.............................. Campo de pesquisa .................................... */}
            <Col lg={5} md={4} xs={4} className="pl-0 my-1 mr-4">
              <Search
                size="large"
                value={this.state.filter}
                onChange={(e) => this.handleChange(e)}
                enterButton
                allowClear
                placeholder="Buscar aluno"
              />
            </Col>
          </Row>
          {/*--------------------------------- TABELA DE RESULTADOS ------------------------------ */}
          <div className="mt-5 mr-5 ">
          {
            !this.props.isLoading && (
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-dark" : "table-row-light"
                }
                rowSelection={{ type: "checkbox" }}
                dataSource={this.state.filter ? this.state.data : data}
                columns={this.state.width >= 935 ? this.columns_lg : (this.state.width >= 760 && this.state.width <= 935) ? this.columns_md : this.columns_xs}
                locale={{
                  emptyText: (
                      <img src={require("../../../assets/images/no-data.png")} alt="Imagem 'não encontrado'"/>
                  ),
                }}
              />
            )
          }
          {
            this.props.isLoading && (
              <Space
              direction="vertical"
              style={{
                width: '100%',
              }}
              align="center"
            >              
              <Spin tip="Carregando" size="large"/>
              </Space>
            )
          }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, user }) => ({
  reports: reports.reports,
  filteredReports: reports.filteredReports,
  type: user.type,
  isLoading: reports.isLoading
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReports: () => dispatch(fetchReports()),
    onSortReports: () => dispatch(sortReports()),
    onFilterByName: (filter) => dispatch(filterByName(filter)),
    onClear: () => dispatch(clearFilter()),
    onFilterByDate: (initialDate, finalDate) =>
      dispatch(filterByDate(initialDate, finalDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuscaRelatorios);
