import {
  SET_MESSAGES,
  SORT_MESSAGES,
  FILTER_MESSAGE_BY_NAME,
  CLEAR_FILTER,
  FILTER_MESSAGE_BY_DATE,
  CHECK_MESSAGES,
  MESSAGE_UPLOADING,
  MESSAGE_SENT,
} from "./actionTypes";
import axios from "axios";
import { fetchReports } from "./reportsActions";
import { message } from "antd";

export const fetchMessages = () => {
  return (dispatch, getState) => {
    dispatch(loadingMessage());
    const token = getState().user.userValidation.token;
    const type = getState().user.type;
    if (type === "responsavel") {
      axios
        .get(
          `/responsaveis/${getState().user.localId}/children.json?auth=${token}`
        )
        .catch((err) =>
          message.error(
            "M01. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
          )
        )
        //Caso o get tenha sido bem sucedido, preenche um array com os resultado e o repassa ao reducer.
        .then((res) => {
          const children = res.data;
          return axios
            .get(`/relatorios.json?auth=${token}`)
            .catch((err) =>
              message.error(
                "M01. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
              )
            )
            .then((res) => {
              const rawReports = res.data;
              let messages = [];
              for (let key in rawReports) {
                if (children) {
                  if (
                    children.includes(rawReports[key].student) &&
                    rawReports[key].verified
                  ) {
                    let rawMessages = rawReports[key].messages;
                    for (let index in rawMessages) {
                      if (rawMessages[index]) {
                        messages.push({
                          ...rawMessages[index],
                          id: index,
                          idReport: key,
                        });
                      }
                    }
                  }
                }
              }
              localStorage.setItem("messages", JSON.stringify(messages));
              dispatch(setMessages(messages));
              dispatch(messageLoaded());
              dispatch(sortMessages());
            });
        });
    }
    if (type === "aluno-responsavel") {
      const name = getState().user.name;
      axios
        .get(`/relatorios.json?auth=${token}`)
        .catch((err) =>
          message.error(
            "M01. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
          )
        )
        .then((res) => {
          const rawReports = res.data;
          let messages = [];
          for (let key in rawReports) {
            if (name == rawReports[key].student && rawReports[key].verified) {
              let rawMessages = rawReports[key].messages;
              for (let index in rawMessages) {
                if (rawMessages[index]) {
                  messages.push({
                    ...rawMessages[index],
                    id: index,
                    idReport: key,
                  });
                }
              }
            }
          }
          localStorage.setItem("messages", JSON.stringify(messages));
          dispatch(setMessages(messages));
          dispatch(messageLoaded());
          dispatch(sortMessages());
        });
    } else {
      dispatch(messageLoaded());
    }
  };
};

export const setMessages = (messages) => {
  if (messages) {
    return {
      type: SET_MESSAGES,
      payload: messages,
    };
  }
};

export const sortMessages = () => {
  return {
    type: SORT_MESSAGES,
  };
};

export const filterByName = (filter) => {
  return {
    type: FILTER_MESSAGE_BY_NAME,
    payload: filter,
  };
};

export const filterByDate = (initialDate, finalDate) => {
  return {
    type: FILTER_MESSAGE_BY_DATE,
    payload: {
      initialDate: initialDate,
      finalDate: finalDate,
    },
  };
};

export const loadingMessage = () => {
  return {
    type: MESSAGE_UPLOADING,
  };
};

export const messageLoaded = () => {
  return {
    type: MESSAGE_SENT,
  };
};

export const markAsSeen = (idReport) => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    axios
      .get(`/relatorios/${idReport}.json?auth=${token}`)
      .catch((err) =>
        message.error(
          "M02. Ocorreu um erro ao recuperar as mensagens dos relatórios. Por favor, tente novamente ou refaça o login."
        )
      )
      .then((res) => {
        const messages = res.data.messages || [];
        messages.forEach((message) => {
          if (message) {
            message.readUser = true;
          }
        });
        axios
          .patch(`/relatorios/${idReport}.json?auth=${token}`, {
            messages,
          })
          .catch((err) =>
            message.error(
              "M02. Ocorreu um erro ao atualizar as mensagens dos relatórios. Por favor, tente novamente ou refaça o login."
            )
          )
          .then(() => {
            dispatch(fetchReports());
          });
      });
  };
};

export const newMessage = (message, idReport) => {
  if (message) {
    return (dispatch, getState) => {
      const token = getState().user.userValidation.token;
      dispatch(loadingMessage());
      axios
        .get(`/relatorios/${idReport}.json?auth=${token}`)
        .catch((err) =>
          message.error(
            "M03. Ocorreu um erro ao recuperar as mensagens dos relatórios. Por favor, tente novamente ou refaça o login."
          )
        )
        .then((res) => {
          const messages = res.data.messages || [];
          messages.push(message);
          axios
            .patch(`/relatorios/${idReport}.json?auth=${token}`, {
              messages,
            })
            .catch((err) =>
              message.error(
                "M03. Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente ou refaça o login."
              )
            )
            .then(() => {
              //dispatch(fetchMessages());
              dispatch(fetchReports());
            });
        });
    };
  }
};

export const checkMessages = () => {
  return {
    type: CHECK_MESSAGES,
  };
};

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};
