import {
  SET_REPORTS,
  SORT_REPORTS,
  FILTER_REPORT_BY_NAME,
  CLEAR_FILTER,
  FILTER_REPORT_BY_DATE,
  EDIT_REPORT,
  UPLOADING_REPORT,
  REPORT_SENT,
} from "../actions/actionTypes";
import moment from "moment";
import _ from "lodash";

const initialState = {
  reports: [],
  filteredReports: [],
  students: [],
  isLoading: false,
};

const unreadReports = (reports) => {
  let unread = [];
  reports.forEach((report) => {
    if (!report.read) {
      unread.push(report);
    }
  });
  unread.sort((a, b) => moment(b.date, 'DD-MM-YYYY') - moment(a.date, 'DD-MM-YYYY'))
  return unread;
};

const readReports = (reports) => {
  let read = [];
  reports.forEach((report) => {
    if (report.read) {
      read.push(report);
    }
  });
  read.sort((a, b) => moment(b.date, 'DD-MM-YYYY') - moment(a.date, 'DD-MM-YYYY'))
  return read;
};

//Função para identificar os relatórios de alunos a partir do filtro (nome passado na busca)
export const filterByName = (reports, filter) => {
  let filtered = [];
  reports.forEach((report) => {
    const name = _.lowerCase(report.student);
    const toCompare = _.lowerCase(filter);
    if (name.includes(toCompare)) {
      filtered.push(report);
    }
  });
  return filtered;
};

export const findPosition = (reports, id) => {
  let position = "";
  for (let i = 0; i < reports.length; i++) {
    if (reports[i].id === id) {
      position = i;
    }
  }
  return position;
};

const filterByDate = (reports, initialDate, finalDate) => {
  let filtered = [];
  const beggining = moment(initialDate, "DD-MM-YYYY").subtract(1, "d");
  const ending = moment(finalDate, "DD-MM-YYYY").add(1, "d");
  reports.forEach((report) => {
    if (moment(report.date, "DD-MM-YYYY").isBetween(beggining, ending)) {
      filtered.push(report);
    }
  });
  return filtered;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
        isLoading: false
      };
    case SORT_REPORTS:
      const sortedReports = unreadReports(state.reports).concat(
        readReports(state.reports)
      );
      return {
        ...state,
        reports: sortedReports,
      };
    case FILTER_REPORT_BY_NAME:
      const namefiltered = filterByName(state.reports, action.payload);
      const filteredArrayName = unreadReports(namefiltered).concat(
        readReports(namefiltered)
      );
      return {
        ...state,
        filteredReports: filteredArrayName,
      };
    case FILTER_REPORT_BY_DATE:
      let datefiltered = filterByDate(
        state.reports,
        action.payload.initialDate,
        action.payload.endDate
      );
      const filteredArray = unreadReports(datefiltered).concat(
        readReports(datefiltered)
      );
      return {
        ...state,
        filteredReports: filteredArray,
      };
    case EDIT_REPORT: {
      const index = findPosition(state.reports, action.payload.id);
      const newState = { ...state };
      newState.reports[index] = action.payload;
      newState.reports[index].read = true;
      return {
        ...newState,
      };
    }
    case UPLOADING_REPORT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case REPORT_SENT: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_FILTER:
      return {
        ...state,
        filteredReports: [],
      };
    default:
      return state;
  }
};

export default reducer;
