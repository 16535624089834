import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  NEW_PICTURE,
  USER_LOADED,
  LOADING_USER,
  USER_NOT_AUTHORIZED,
  SET_PUSH_NOTIFICAITON_TOKEN,
} from "./actionTypes";
import axios from "axios";
import { fetchMessages } from "./messagesActions";
import { fetchReports } from "./reportsActions";
import { history } from "../../../App"
import { message } from "antd";
import { fetchUserEvents } from "./calendarActions";
import { fetchAllUsers } from "./registerActions";

const functionsBaseURL =
  "https://us-central1-banco-de-dados-realize.cloudfunctions.net";

export const userLogged = (user) => {
  return {
    type: USER_LOGGED_IN,
    payload: user,
  };
};

export const logout = () => {
  return {
    type: USER_LOGGED_OUT,
  };
};

export const setPicture = (user) => {
  return {
    type: NEW_PICTURE,
    payload: user,
  };
};

export const uploadPicture = (image) => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token
    axios({
      url: "uploadImage",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        image: image.base64,
      },
    })
      .catch((err) => message.error("U01. Ocorreu um erro no upload da imagem. Por favor, tente novamente ou refaça o login."))
      .then((resp) => {
        const user = getState().user;
        user.image = resp.data.imageUrl;
        axios
          .patch(`/usuarios/${user.localId}.json?auth=${token}`, {
            image: user.image,
          })
          .catch((err) => message.error("U01. Ocorreu um erro ao salvar a imagem no banco de dados. Por favor, tente novamente ou refaça o login."))
          .then(() => {
            dispatch(setPicture(user));
          });
      });
  };
};

export const loadingUser = () => {
  return {
    type: LOADING_USER,
  };
};

export const userLoaded = () => {
  return {
    type: USER_LOADED,
  };
};

export const userNotAuthorized = () => {
  return {
    type: USER_NOT_AUTHORIZED,
  };
};

export const setTokenNotification = (token) => {
  return {
    type: SET_PUSH_NOTIFICAITON_TOKEN,
    payload: token,
  };
};

//Action para login do usuário
export const login = (user) => {
  return (dispatch) => {
    dispatch(loadingUser());
    //Post para validação das informações recebidas.  Ver ./functions/index.js -> CloudFunctions.
    axios({
      url: "login",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        email: user.email,
        password: user.password,
      },
    })
      //Se o usuário for do tipo admin carrega as informações no objeto user e userData
      //user será repassado a store, userData será salvo no aparelho, para posterior verificação
      //sem necessidade de novo login, desde que o token ainda esteja válido.
      .then((res) => {
        if (res.data.user.type === "admin" || res.data.user.type.includes("responsavel")) {
          user.image = res.data.user.image ? res.data.user.image : null;
          user.password = "";
          user.name = res.data.user.name;
          user.token = res.data.user.token;
          user.localId = res.data.user.localId;
          user.type = res.data.user.type;
          user.refreshToken = res.data.user.refreshToken;
          user.tokenSetOn = new Date()
          dispatch(userLogged(user));
          dispatch(userLoaded());
          //dispatch(fetchMessages());
          dispatch(fetchReports());
          //dispatch(fetchAllUsers())
          //dispatch(fetchUserEvents());
          localStorage.setItem("userData", JSON.stringify(user));
        } else {
          message.error("U02. Usuário não possui permissão para a aplicação.");
          dispatch(userLoaded());
        }
      })
      //Para erros de validação
      .catch((err) => {
        console.log(err)
        dispatch(userNotAuthorized());
        dispatch(clear());
        message.error("U02. Erro ao realizar login. Por favor, entre em contato com a administração.");
      });
  };
};


//Action para atualização de senha.
export const updatePassword = (localId, newPassword) => {
  return (dispatch) => {
    dispatch(loadingUser());
    axios({
      url: "updatePassword",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        uid: localId,
        newPassword: newPassword,
      },
    }) //Post com a nova senha. Ver ./functions/index.js -> CloudFunctions.
      .then(() => {
        dispatch(userLoaded());
      })
      .catch((err) => {
        message.error("U03. Ocorreu um erro ao atualizar a senha. Por favor, tente novamente ou refaça o login.")
      });
  };
};

//Verifica validade do token com um get e redireciona para a página de login,
//caso o token não esteja válido
export const checkToken = (token, localId, refreshToken) => {
  return (dispatch, getState) => {
    axios
      .get(`/usuarios/${localId}.json?auth=${token}`)
      .then(res => {
        let user = {};
        if (res.data.type === "admin" || res.data.type.includes("responsavel") || res.data.type.includes("aluno") ) {
          user.image = res.data.user.image ? res.data.user.image : null;
          user.password = "";
          user.name = res.data.user.name;
          user.token = token;
          user.localId = localId;
          user.type = res.data.user.type;
          user.refreshToken = refreshToken;
          user.tokenSetOn = getState().user.userValidation.tokenSetOn
          dispatch(userLogged(user));
          dispatch(userLoaded());
          dispatch(fetchMessages());
          dispatch(fetchReports());
          //dispatch(fetchAllUsers());
          //dispatch(fetchUserEvents());
          localStorage.setItem("userData", JSON.stringify(user));
        } else {
          message.error("U04. Usuário não possui permissão para a aplicação.");
          dispatch(userLoaded());
        }
      })
    .catch(err => {
        dispatch(refreshUserToken(localId, refreshToken));
      });
  };
};

//Limpa as informações do AsyncStorage e faz o dispatch para limpeza da store
export const clear = () => {
  return (dispatch) => {
    localStorage.removeItem("reports")
    localStorage.removeItem("userData")
    history.push("/")
    dispatch(logout());
  };
};

export const refreshUserToken = (localId, refreshToken) => {
  return (dispatch) => {
    axios({
      url: "refreshToken",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        refreshToken: refreshToken,
      },
    })
      .then((res) => {
        dispatch(checkToken(res.data.idToken, localId, refreshToken));
      })
      .catch((err) => {
        message.error("U05. Sua seção expirou.");
        dispatch(userNotAuthorized());
        dispatch(clear());
      });
  };
};


//Action para atualização de senha.
export const requestNewPassword = (email) => {
  if (email.trim() !== "") {
  message.info('Aguarde um momento.')
    axios({
      url: "requestNewPassword",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        email: email,
      },
    }) //Post com a nova senha. Ver ./functions/index.js -> CloudFunctions.
      .then(() => {
        message.success('Enviamos um e-mail com informações. Ele pode levar alguns instantes para ser entregue.')
      })
      .catch((err) => {
        message.error("Erro ao realizar requisição.");
      });
  } else {
    console.log('erro', email)
  }
};

//Action para atualização de senha.
export const resetPassword = (oobCode, password) => {
  if (password.trim() !== "" && oobCode.trim() !== "" ) {
  message.info('Aguarde um momento.')
    axios({
      url: "resetPassword",
      baseURL: functionsBaseURL,
      method: "post",
      data: {
        password: password,
        oobCode: oobCode,
      },
    }) //Post com a nova senha. Ver ./functions/index.js -> CloudFunctions.
      .then(() => {
        message.success('Senha alterada com sucesso.')
        history.push("/redirect")
      })
      .catch((err) => {
        message.error("Erro ao atualizar senha.");
      });
  } else {
    message.error('Preencha todas as informações.')
  }
};