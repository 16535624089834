import {
  SET_USERS_FOR_REGISTER,
  SET_USER_INFO,
  FETCHING_INFO,
  INFO_LOADED,
  UPDATING_INFO,
  INFO_UPDATED,
  FILTER_USERS_BY_NAME,
  CLEAR_FILTER,
  SET_STUDENTS,
  SET_TUTORS,
} from "../actions/actionTypes";
import axios from "axios";
import { message } from "antd";

const functionsBaseURL =
  "https://us-central1-banco-de-dados-realize.cloudfunctions.net";

export const fetchAllUsers = () => {
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    axios
      .get(`/responsaveis/${getState().user.localId}/children.json?auth=${token}`)
      .catch((err) =>
        message.error(
          "R01. Ocorreu um erro. Por favor, tente novamente ou refaça o login."
        )
      )
      //Caso o get tenha sido bem sucedido, preenche um array com os resultado e o repassa ao reducer.
      .then((res) => {
        const children = res.data;
        axios
          .get(`/usuarios.json?auth=${token}`)
          .catch((err) =>
            message.error(
              "RG01. Ocorreu um erro ao recuperar as informações dos usuários. Por favor, tente novamente ou refaça o login."
            )
          )
          .then((res) => {
            const rawData = res.data;
            let students = [];
            for (let key in rawData) {
              if(children){
              if (children.includes(rawData[key].name)) {
                students.push({ ...rawData[key], localId: key });
              }}
            }
            console.log(students)
            dispatch(setStudents(students));
            dispatch(infoLoaded());
          });
      });
  };
};

export const setUsersforRegister = (users) => {
  return {
    type: SET_USERS_FOR_REGISTER,
    payload: users,
  };
};

export const fetchUserInfos = (type, localId) => {
  const folder = databaseFolder(type);
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    axios.get(`/${folder}/${localId}.json?auth=${token}`).then(
      (res) => {
        const userInfos = res.data;
        dispatch(setUserInfos(userInfos));
        dispatch(infoLoaded());
      },
      (err) =>
        message.error(
          "RG02. Ocorreu um erro ao recuperar a informação dos usuário. Por favor, tente novamente ou refaça o login."
        )
    );
  };
};

export const fetchUser = (localId) => {
  return (dispatch, getState) => {
    dispatch(loadingInfo());
    const token = getState().user.userValidation.token;
    axios
      .get(`/usuarios/${localId}.json?auth=${token}`)
      .then((res) => {
        dispatch(fetchUserInfos(res.data.type, localId));
      })
      .catch((err) =>
        message.error(
          "RG03. Ocorreu um erro ao recuperar a informação dos usuário. Por favor, tente novamente ou refaça o login."
        )
      );
  };
};

const loadingInfo = () => {
  return {
    type: FETCHING_INFO,
  };
};

const infoLoaded = () => {
  return {
    type: INFO_LOADED,
  };
};

const setUserInfos = (info) => {
  return {
    type: SET_USER_INFO,
    payload: info,
  };
};

export const fetchStudents = () => {
  return (dispatch, getState) => {
    const token = getState().user.userValidation.token;
    axios
      .get(`/usuarios.json?auth=${token}`)
      .catch((err) =>
        message.error(
          "RP03. Ocorreu um erro ao recuperar as informações dos alunos. Por favor, tente novamente ou refaça o login."
        )
      )
      .then((res) => {
        let students = [];
        const rawData = res.data;
        for (let key in rawData) {
          if (rawData[key].type === "aluno") {
            students.push(rawData[key].name);
          }
        }
        dispatch(setStudents(students));
        return students;
      });
  };
};

export const setFilteredUsers = (filter, type) => {
  return {
    type: FILTER_USERS_BY_NAME,
    payload: {
      filter: filter,
      type: type,
    },
  };
};

const updatingInfo = () => {
  return {
    type: UPDATING_INFO,
  };
};

const infoUpdated = () => {
  return {
    type: INFO_UPDATED,
  };
};

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

export const setTutors = (tutors) => {
  return {
    type: SET_TUTORS,
    payload: tutors,
  };
};

export const setStudents = (students) => {
  return {
    type: SET_STUDENTS,
    payload: students,
  };
};

const databaseFolder = (type) => {
  let folder = "";
  switch (type) {
    case "aluno":
      folder = "alunos";
      break;
    case "admin":
      folder = "admin";
      break;
    case "orientador":
      folder = "orientadores";
      break;
    case "responsavel":
      folder = "responsaveis";
      break;
    case "tutor":
      folder = "tutores";
      break;
    default:
      folder = "";
      break;
  }
  return folder;
};
